<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           管理员进行耗材入库时，填写入库数量错误，可用库存调整进行调整库存
         </div>   
		<TableVue ref="c_table" v-on="$listeners"></TableVue>
	</div>   
</template>
<script>
import TableVue from '../Common/ConsumableTable.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/CseAdjustStock/index',
				add:'/CseAdjustStock/add',
				edit:'/CseAdjustStock/edit',
				del:'/CseAdjustStock/del',
				export:'/CseAdjustStock/export'
			} 
			this.$refs.c_table.cardName =  '库存调整单'
			this.$refs.c_table.storageType = 6 
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>