<template>
<div class="page-container">
	<div class="search-form">
		<el-form ref="form" :inline="true"  :model="search_key" prop="">
			<el-form-item label="">
				<el-input v-model="search_key.receipt_number" placeholder="输入关键字搜索"></el-input>
			</el-form-item>
			
		<el-button type="primary" @click="getData()">查询</el-button>
		<el-button type="primary" @click="handleExport()">导出全部</el-button>
		</el-form>
	</div>
	 <div style="margin-bottom:10px;">
		<template v-if="!batchStatus">
			<el-button size="small" type="" @click="handleAdd()">添加</el-button>
		</template>
		<template v-else>
			<!-- <el-button size="small" type="" @click="handlePrint()">打印</el-button> -->
			<el-button size="small" type="" @click="handleExport()">导出</el-button> 
			<el-button size="small" type="danger" plain @click="handleDel(multipleSelection)">删除</el-button>
		</template>
	 </div>
	 <el-table
		v-loading="loading"
		element-loading-text="查询中"
		:data="tableData" 
		 :row-key="getRowKeys"
		  @selection-change="handleSelectionChange"
		style="width: 100%;">
		<el-table-column
		type="selection"
		:reserve-selection="true"
		width="55">
		</el-table-column>
		<template v-if="expandStatus">
		<el-table-column  type="expand">
			<template  slot-scope="props">
				<el-table	
				style="width: 90%;margin: 0 auto;"
				:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
				:row-style="{height:'40px'}"
				 :cell-style="{padding: '0'}"
				border
				:data="props.row.file_list">
					 <el-table-column
							 prop="mtl_code"
							 label="物料编码">
						 </el-table-column>
						  <el-table-column
							 prop="name"
							 label="物料名称">
						 </el-table-column>
						 <el-table-column 
							 prop="cate_name"
							 label="物料分类">
						 </el-table-column>
						 <el-table-column
							 prop="barand"
							 empty-text='-'
							 label="品牌">
						 </el-table-column>
						 <el-table-column
							 prop="model"
							 empty-text='-'
							 label="规格型号">
						 </el-table-column>
						 <el-table-column
							 prop="unit"
							 empty-text='-'
							 label="计量单位">
						 </el-table-column>
						 <el-table-column
							v-if="storageType != 1"
							 prop="stock"
							 empty-text='-'
							 label="可用库存">
						 </el-table-column>	
						<el-table-column
							 prop="price"
							 empty-text='-'
							 label="单价">
							 <template slot-scope="scope">
								 <span v-if="scope.row.price">{{scope.row.price}}</span>
								 <!-- <span v-if="scope.row.blocked_stock || scope.row.stock">{{scope.row.total_prices / (scope.row.stock + scope.row.blocked_stock)}}</span> -->
							 </template>
						</el-table-column>
						<el-table-column
							v-if="storageType != 6"
							 prop=""
							 empty-text='-' 
							 label="">
							<template slot="header" slot-scope="props">
								<span v-if="storageType == 1">入库数量</span>
								<span v-if="storageType == 2">派发数量</span>
								<span v-if="storageType == 3">退库数量</span>
								<span v-if="storageType == 4">调拨数量</span>
								<span v-if="storageType == 5">处置数量</span>
							</template>
							 <template slot-scope="scope">
								 <span v-if="scope.row.number">{{scope.row.number}}</span>
								 <!-- <span v-if="scope.row.blocked_stock || scope.row.stock">{{scope.row.stock + scope.row.blocked_stock}}</span> -->
							 </template>
						</el-table-column>
						 <el-table-column
							v-if="storageType == 1"
							 prop="total_prices"
							 empty-text='-'
							 label="总价">
						 </el-table-column>
						
				</el-table>
			</template>
		</el-table-column>
		</template>
		<template v-for="item in theadData">
			<el-table-column :key="item.field_name" :label="item.label" empty-text='-'>
				<template slot-scope="scope">
					<span>{{scope.row[item.field_name]}}</span>
				</template>
			</el-table-column>
		</template>
		
		<el-table-column
			label="操作"
			min-width="150px"
			>
			<template slot-scope="scope">
				<el-button  size="small" @click="handleApproval(scope.row.id)">审批信息</el-button>
				<!-- <el-button  size="small" @click="handleEdit(scope.row)">修改</el-button> -->
				<!-- <el-button  size="small" @click="handlePrint(scope.row)">打印</el-button> -->
				<el-button  size="small" type="danger" plain @click="handleDel(scope.row.id)">删除	</el-button>
			</template>
		</el-table-column> 
	 </el-table> 
	<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
	<AddVue ref='childDialog' v-on="$listeners"></AddVue>
	 <ApprovalVue ref="approval" ></ApprovalVue>
    </div>
</template>
<script>
import AddVue from './ConsumableAdd.vue'
import ApprovalVue from './ApprovalDrawer.vue'
export default {
    components:{
        AddVue,
		ApprovalVue
    },
   data() {
      return {
		  search_key:{
			  receipt_number:''
		  },
		  batchStatus:false,
		  cardName:'',
		  // cardType:2,
		  getRowKeys(row){
		      return row.id
		  },
		  tableData:[],
		  currentPage:1,
		  pagesize:10,
		  total:0,
		  theadData:[],
		  assetTheadData:[],
		  addDialogTitle:'',
		  rUrl:{
			  index:'',
			  add:'',
			  del:'',
			  edit:'',
			  export:''
		  },
		  storageType:1,
		  expandStatus:true,
		  loading:true,
      }
   },
   created(){
   },
   mounted(){
		
   },
   methods:{
       getData(){   //表格数据
            var arr = []
            this.$api.post(this.rUrl.index,{
				receipt_number:this.search_key.receipt_number,
				card_name:this.cardName,
				type:this.storageType,
			},res=>{
               if (res.code == 200) {
                   this.tableData =  res.data.list??[]
				   this.theadData =  res.data.thead
                   this.total =  res.data.count
				  
               }else{
				   this.$message.error(res.msg)
			   }
			    this.loading =  false
           })
       },

         //监听表格多选框
       handleSelectionChange(val){
           this.multipleSelection  = []
           var list = val;
            list.forEach(item=>{
                this.multipleSelection.push(item.id )
            })
			if(this.multipleSelection.length > 0){
				this.batchStatus = true
			}else{
				this.batchStatus = false
			}
			console.log('multi:',this.multipleSelection)
       },
		handleAdd(){
			this.$nextTick(()=>{
				this.$refs.childDialog.cardName = this.cardName
				this.$refs.childDialog.addUrl  = this.rUrl.add
				this.$refs.childDialog.editUrl =  this.rUrl.edit
				this.$refs.childDialog.storageType = this.storageType
				this.$refs.childDialog.handleAdd()
			})
			
		},
	   handleEdit(id){
		   this.$refs.editChildDialog.handleEdit(id)
	   },

		handleApproval(id){
			this.$refs.approval.approvelType  = 2
			this.$refs.approval.handleApproval(id,this.storageType,this.cardName)
		},
		handleExport(){
			var id = this.multipleSelection?this.multipleSelection.join(','):''
			location.href = '/api/admin'+this.rUrl.export+'?id='+id
		},
		handleDel(id=0){
			let ids = []
			if(id > 0){
				ids.push(id)
			}else{
				ids =  this.multipleSelection
			}
			this.$alert("确定删除选中数据吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete(this.rUrl.del, {
							id:ids
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		handlePrint(){},

        getCurrPage(val){   //获取当前页
            this.currentPage =  val
			this.loading =  true
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
			this.loading =  true
            this.getData()
        },
   }

}
</script>
<style lang="scss">

  .bg-purple {
      padding:10px;
    background:  #d9ecff;
  }
  .bg-purple-light {
    background: #d9ecff;
  }

</style>